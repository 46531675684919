import {ReducersMapObject} from 'redux';

import {BaseApplicationState, BaseAppThunkAction, baseReducers} from '@common/react/store';

import {User} from '@app/objects/User';

// The top-level state object
export interface ApplicationState extends BaseApplicationState<User> {
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers: ReducersMapObject = {
	...baseReducers
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> extends BaseAppThunkAction<TAction, User, ApplicationState> {}
