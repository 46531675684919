import React from 'react';

import '@app/scss/components/customCursor.scss';

const CustomCursor: React.FC<{size1?: number, size2?: number}> = ({size1 = 22, size2 = 48}) => {
	const circle1 = React.useRef<HTMLDivElement>(null);
	const circle2 = React.useRef<HTMLDivElement>(null);
	
	React.useEffect(() => {
		const handler = (e) => {
			if (circle1.current && circle2.current) {
				circle1.current.style.transform = `translate(${e.clientX - size1 / 2}px, ${e.clientY - size1 / 2}px)`;
				circle2.current.style.transform = `translate(${e.clientX - size2 / 2}px, ${e.clientY - size2 / 2}px)`;
			}
		};
		window.addEventListener('mousemove', handler);
		return () => {
			window.removeEventListener('mousemove', handler);
		};
	}, []);

	return <>
		<div className="cursor-circle-1" ref={circle1}/>
		<div className="cursor-circle-2" ref={circle2}/>
		<style>
			{`${''}body {
				cursor: none;
			}`}
		</style>
	</>;
};

export default CustomCursor;
