import * as React from 'react';
import { Route, RouteProps } from 'react-router-dom';

import Layout from '@app/components/Layout';

interface Props extends RouteProps {
	component: any;
}

export const MainRoute: React.FC<Props> = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={props => <Layout>
			<Component {...props} />
		</Layout>}
	/>
);