import * as React from 'react';

import gsap from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';

import 'antd/lib/style/index.css';

import '@app/scss/style.scss';

import Header from '@app/components/UI/Header/Header';
import NewFooter from '@app/components/UI/Footer/NewFooter';
import CustomCursor from '@app/components/UI/CustomCursor';

gsap.registerPlugin(ScrollTrigger);

const Layout: React.FC = ({children}) => {
	const [show, setShow] = React.useState(false);

	React.useEffect(() => {
		if (show) {
			const script = document.getElementById('birdeye-widget');

			if (!script) {
				const script = document.createElement('script');

				script.src = 'https://birdeye.com/embed/v6/94248/1/3189547842/8e7f308cede19a95f9006bfee7ae5ee2735c82a159d1706e';
				script.defer = true;
				script.type = 'text/javascript';
				script.id = 'birdeye-widget';

				document.body.appendChild(script);
			}
		}
	}, [show]);

	const handler = React.useCallback(() => setShow(true), []);

	React.useEffect(() => {
		window.addEventListener('scroll', () => {
			handler();
			window.removeEventListener('scroll', handler);
		});
	}, []);

	return <div
		onPointerMove={handler}
		onPointerEnter={handler}
		onPointerDown={handler}
		className="app-container"
	>
		<CustomCursor/>
		<Header/>
		{children}
		<NewFooter/>
	</div>;
};

export default Layout;
