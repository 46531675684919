import * as React from 'react';
import { NavLink, useHistory } from 'react-router-dom';

import 'antd/lib/menu/style/index.css';
import 'antd/lib/dropdown/style/index.css';
import 'antd/lib/tooltip/style/index.css';

interface HeaderState {
	isMenuOpen: boolean;
	menuType: 'horizontal' | 'vertical';
	menuClass: 'menu-top' | 'menu-hide';
}

const menuItems = [
	{
		key: 'home',
		label: <NavLink exact to="/" className="nav-link-2">Home<span/></NavLink>,
		mobileLabel: <NavLink exact to="/" className="mobile-menu-link">
			<div  className="mobile-menu-text">01</div>
			Home
		</NavLink>
	},
	{
		key: 'services',
		label: <NavLink to="/services" className="nav-link-2">Services<span/></NavLink>,
		mobileLabel: <NavLink exact to="/services" className="mobile-menu-link">
			<div className="mobile-menu-text">02</div>
			Services
		</NavLink>
	},
	{
		key: 'about',
		label: <NavLink to="/about-us" className="nav-link-2">About<span/></NavLink>,
		mobileLabel: <NavLink exact to="/about-us" className="mobile-menu-link">
			<div className="mobile-menu-text">03</div>
			About
		</NavLink>
	},
	{
		key: 'contactus',
		label: <NavLink to="/contact-us" className="nav-link-2">Demo<span/></NavLink>,
		mobileLabel: <NavLink exact to="/contact-us" className="mobile-menu-link">
			<div className="mobile-menu-text">04</div>
			Demo
		</NavLink>
	}
];

const Header: React.FC = () => {
	const history = useHistory();
	const [state, setState] = React.useState<HeaderState>({
		isMenuOpen: false,
		menuType: 'vertical',
		menuClass: 'menu-top',
	});
	const scrollData = React.useRef({lastScrollTop: 0});

	React.useEffect(() => {
		const handleScroll = () => {
			window.requestAnimationFrame(() => {
				setState(prev => ({
					...prev,
					menuClass: scrollData.current.lastScrollTop >= window.scrollY ? 'menu-top' : 'menu-hide'
				}));
				scrollData.current.lastScrollTop = window.scrollY;
			});
		};
		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	React.useEffect(() => {
		window.scrollTo({top: 0, left: 0, behavior: 'instant' as any});
	}, [history.location.pathname]);

	return (
		<header
			id="header"
			className="header-transparent header-effect-shrink"
		>
			<div className={`header-body ${state.menuClass}`}>
				<div className="header-container container header-row">
					<div className="container-2">
						<div className="navbar-wrapper">
							<div className="header-logo">
								<NavLink to="/">
									<picture>
										<source media="(max-width: 768px)" srcSet="/images/logo-mobile.png"/>
										<source media="(min-width: 769px)" srcSet="/images/logo.png"/>
										<img width="150" height="65" className="waves" alt="waves" style={{ top: 0 }} aria-hidden="true" src="/images/logo.png"/>
									</picture>
								</NavLink>
							</div>
							<nav role="navigation" className="nav-menu-wrapper w-nav-menu">
								<ul role="list" className="nav-menu-2 w-list-unstyled">
									{menuItems.map(item => <li key={item.key}>
										{item.label}
									</li>)}
								</ul>
							</nav>
							<a href="https://dealers.executivetags.com/" target="_blank" className="primary-button w-inline-block">
								<div className="wrapper-2">
									<div className="primary-button-text smaller remo">Login</div>
								</div>
								<div className="primary-button-shadow"/>
							</a>
							<div className="tm-nav-button-wrapper">
								<a href="https://dealers.executivetags.com/" className="login">Login</a>
								<div
									className="tm-nav-menu-button w-nav-button"
									onClick={() => setState(prev => ({...prev, isMenuOpen: !prev.isMenuOpen}))}>
									<div className={`tm-nav-menu-icon2 tm-nav-menu-button w-nav-button ${state.isMenuOpen ? 'open' : ''}`}>
										<div className="tm-nav-menu-icon2_line-top"/>
										<div className="tm-nav-menu-icon2_line-middle">
											<div className="tm-nav-menu-icon_line-middle-inner"/>
										</div>
										<div className="tm-nav-menu-icon2_line-bottom"/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className={`mobile-menu-container ${state.isMenuOpen ? 'open' : ''}`}>
					<nav className="mobile-menu">
						<ul className="mobile-menu-list">
							{menuItems.map((item, i) => <li
								key={item.key} 
								onClick={() => setState(prev => ({...prev, isMenuOpen: false}))}
							>
								{item.mobileLabel}
							</li>)}
						</ul>
						<div className="menu-socials">
							<a
								href="https://www.facebook.com/executivetags/"
								target="_blank"
								className="menu-social w-inline-block"
							>
								<div className="menu-social-text">Facebook</div>
							</a>
							<a
								href="https://www.instagram.com/executivetags/?hl=de"
								target="_blank"
								className="menu-social w-inline-block"
							>
								<div className="menu-social-text">Instagram</div>
							</a>
							<a
								href="https://www.linkedin.com/company/executive-tag-&amp;-title-svcs.-inc."
								target="_blank"
								className="menu-social w-inline-block"
							>
								<div className="menu-social-text">Linked In</div>
							</a>
						</div>
					</nav>
				</div>
				<div className="nav-bg-white"/>
			</div>
		</header>);
};

export default Header;
