import * as React from 'react';
import {Switch} from 'react-router-dom';

import loadable from '@loadable/component';

import {loadableDelay, params} from '@common/react/loadable/loadableSettings';

import { MainRoute } from '@app/components/Routes/MainRoute';

const Home = loadable(() => 
	loadableDelay(import(/* webpackChunkName: "HomePage" */ './components/Pages/Home/Home')), params);
const AboutUs = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "AboutUs" */ './components/Pages/AboutUs/AboutUs')), params);
const ContactUs = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ContactUs" */ './components/Pages/ContactUs/ContactUs')), params);
const Services = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "Services" */ './components/Pages/Services/Services')), params);
const PrivacyPolicy = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "PrivacyPolicy" */ './components/Pages/PrivacyPolicy/PrivacyPolicy')), params);
const TermsOfUse = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "TermsOfUse" */ './components/Pages/TermsOfUse/TermsOfUse')), params);

export const routes = <Switch>
	<MainRoute exact path="/" component={Home}/>
	<MainRoute exact path="/about-us" component={AboutUs}/>
	<MainRoute exact path="/contact-us" component={ContactUs}/>
	<MainRoute exact path="/services" component={Services}/>
	<MainRoute exact path="/privacy-policy" component={PrivacyPolicy}/>
	<MainRoute exact path="/terms-of-use" component={TermsOfUse}/>
</Switch>;
